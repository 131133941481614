<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <h1 class="h3">Add Correlation Strength Analysis</h1>
      </div>
      <div class="col-md-4">
        <select v-model="correlation_type" class="form-control">
          <option value="5-min">5 Minutes</option>
          <option value="15-min">15 Minutes</option>
          <option value="1-hour">1 Hour</option>
          <option value="4-hour">4 Hour</option>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="6-month">6 Months</option>
          <option value="12-month">12 Months</option>
        </select>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="save">
          <table class="table table-responsive table-bordered">
            <thead>
              <tr>
                <th></th>
                <th>AUD</th>
                <th>CAD</th>
                <th>CHF</th>
                <th>EUR</th>
                <th>GBP</th>
                <th>NZD</th>
                <th>JPY</th>
                <th>USD</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td class="px-0 py-0">
                  <table class="w-100 table-borderless text-center">
                    <tbody>
                      <tr
                        v-for="(value, pair) in currencies.aud"
                        :key="`aud-$${pair}`"
                      >
                        <td class="border-bottom">
                          <p class="mb-0 text-uppercase" v-html="pair"></p>
                          <select
                            class="w-100 px-2"
                            required
                            v-model="aud[pair]"
                          >
                            <option value="-1">-1</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td class="px-0 py-0">
                  <table class="w-100 table-borderless text-center">
                    <tbody>
                      <tr
                        v-for="(value, pair) in currencies.cad"
                        :key="`cad-$${pair}`"
                      >
                        <td class="border-bottom">
                          <p class="mb-0 text-uppercase" v-html="pair"></p>
                          <select
                            class="w-100 px-2"
                            required
                            v-model="cad[pair]"
                          >
                            <option value="-1">-1</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td class="px-0 py-0">
                  <table class="w-100 table-borderless text-center">
                    <tbody>
                      <tr
                        v-for="(value, pair) in currencies.chf"
                        :key="`chf-$${pair}`"
                      >
                        <td class="border-bottom">
                          <p class="mb-0 text-uppercase" v-html="pair"></p>
                          <select
                            class="w-100 px-2"
                            required
                            v-model="chf[pair]"
                          >
                            <option value="-1">-1</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td class="px-0 py-0">
                  <table class="w-100 table-borderless text-center">
                    <tbody>
                      <tr
                        v-for="(value, pair) in currencies.eur"
                        :key="`eur-$${pair}`"
                      >
                        <td class="border-bottom">
                          <p class="mb-0 text-uppercase" v-html="pair"></p>
                          <select
                            class="w-100 px-2"
                            required
                            v-model="eur[pair]"
                          >
                            <option value="-1">-1</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td class="px-0 py-0">
                  <table class="w-100 table-borderless text-center">
                    <tbody>
                      <tr
                        v-for="(value, pair) in currencies.gbp"
                        :key="`gbp-$${pair}`"
                      >
                        <td class="border-bottom">
                          <p class="mb-0 text-uppercase" v-html="pair"></p>
                          <select
                            class="w-100 px-2"
                            required
                            v-model="gbp[pair]"
                          >
                            <option value="-1">-1</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td class="px-0 py-0">
                  <table class="w-100 table-borderless text-center">
                    <tbody>
                      <tr
                        v-for="(value, pair) in currencies.nzd"
                        :key="`nzd-$${pair}`"
                      >
                        <td class="border-bottom">
                          <p class="mb-0 text-uppercase" v-html="pair"></p>
                          <select
                            class="w-100 px-2"
                            required
                            v-model="nzd[pair]"
                          >
                            <option value="-1">-1</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td class="px-0 py-0">
                  <table class="w-100 table-borderless text-center">
                    <tbody>
                      <tr
                        v-for="(value, pair) in currencies.jpy"
                        :key="`jpy-$${pair}`"
                      >
                        <td class="border-bottom">
                          <p class="mb-0 text-uppercase" v-html="pair"></p>
                          <select
                            class="w-100 px-2"
                            required
                            v-model="jpy[pair]"
                          >
                            <option value="-1">-1</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td class="px-0 py-0">
                  <table class="w-100 table-borderless text-center">
                    <tbody>
                      <tr
                        v-for="(value, pair) in currencies.usd"
                        :key="`usd-$${pair}`"
                      >
                        <td class="border-bottom">
                          <p class="mb-0 text-uppercase" v-html="pair"></p>
                          <select
                            class="w-100 px-2"
                            required
                            v-model="usd[pair]"
                          >
                            <option value="-1">-1</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            <tfoot class="text-center">
              <tr>
                <th>Total</th>
                <th>{{ total_aud }}</th>
                <th>{{ total_cad }}</th>
                <th>{{ total_chf }}</th>
                <th>{{ total_eur }}</th>
                <th>{{ total_gbp }}</th>
                <th>{{ total_nzd }}</th>
                <th>{{ total_jpy }}</th>
                <th>{{ total_usd }}</th>
              </tr>
            </tfoot>
          </table>
          <h2 class="card-title mt-3">Analysis</h2>
          <div class="row">
            <div class="col-md-4">
              <h4>Strong</h4>
              <ul>
                <li v-if="total_aud >= 4">AUD</li>
                <li v-if="total_cad >= 4">CAD</li>
                <li v-if="total_chf >= 4">CHF</li>
                <li v-if="total_eur >= 4">EUR</li>
                <li v-if="total_gbp >= 4">GBP</li>
                <li v-if="total_nzd >= 4">NZD</li>
                <li v-if="total_jpy >= 4">JPY</li>
                <li v-if="total_usd >= 4">USD</li>
              </ul>
            </div>
            <div class="col-md-4">
              <h4>Neutral</h4>
              <ul>
                <li v-if="total_aud <= 3 && total_aud >= -3">AUD</li>
                <li v-if="total_cad <= 3 && total_cad >= -3">CAD</li>
                <li v-if="total_chf <= 3 && total_chf >= -3">CHF</li>
                <li v-if="total_eur <= 3 && total_eur >= -3">EUR</li>
                <li v-if="total_gbp <= 3 && total_gbp >= -3">GBP</li>
                <li v-if="total_nzd <= 3 && total_nzd >= -3">NZD</li>
                <li v-if="total_jpy <= 3 && total_jpy >= -3">JPY</li>
                <li v-if="total_usd <= 3 && total_usd >= -3">USD</li>
              </ul>
            </div>
            <div class="col-md-4">
              <h4>Weak</h4>
              <ul>
                <li v-if="total_aud < -3">AUD</li>
                <li v-if="total_cad < -3">CAD</li>
                <li v-if="total_chf < -3">CHF</li>
                <li v-if="total_eur < -3">EUR</li>
                <li v-if="total_gbp < -3">GBP</li>
                <li v-if="total_nzd < -3">NZD</li>
                <li v-if="total_jpy < -3">JPY</li>
                <li v-if="total_usd < -3">USD</li>
              </ul>
            </div>
            <div class="col-md-12 text-right">
              <button class="btn btn-primary">
                <i class="lni lni-save mr-2"></i> Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { currencies } from "./currencies";

export default {
  data() {
    return {
      currencies: currencies,
      aud: {},
      cad: {},
      chf: {},
      eur: {},
      gbp: {},
      nzd: {},
      jpy: {},
      usd: {},
      correlation_type: '1-hour'
    };
  },

  methods: {
    save() {
      let data = {
        type: this.correlation_type,
        currencies: {
          aud: this.aud,
          cad: this.cad,
          chf: this.chf,
          eur: this.eur,
          gbp: this.gbp,
          nzd: this.nzd,
          jpy: this.jpy,
          usd: this.usd
        }
      }

      this.$loader.start()
      this.$axios.post('/api/v1/admin/correlation', data).then(() => {
        this.$router.push({ name: 'admin.correlation.index' })
        this.$toasted.show('Correlation data has been saved', { duration: 5000 })
        this.$loader.stop()
      })
    }
  },

  computed: {
    total_aud() {
      let total = 0;

      Object.values(this.aud).forEach((value) => {
        total += parseInt(value);
      });

      return total;
    },

    total_cad() {
      let total = 0;

      Object.values(this.cad).forEach((value) => {
        total += parseInt(value);
      });

      return total;
    },

    total_chf() {
      let total = 0;

      Object.values(this.chf).forEach((value) => {
        total += parseInt(value);
      });

      return total;
    },

    total_eur() {
      let total = 0;

      Object.values(this.eur).forEach((value) => {
        total += parseInt(value);
      });

      return total;
    },

    total_gbp() {
      let total = 0;

      Object.values(this.gbp).forEach((value) => {
        total += parseInt(value);
      });

      return total;
    },

    total_nzd() {
      let total = 0;

      Object.values(this.nzd).forEach((value) => {
        total += parseInt(value);
      });

      return total;
    },

    total_jpy() {
      let total = 0;

      Object.values(this.jpy).forEach((value) => {
        total += parseInt(value);
      });

      return total;
    },

    total_usd() {
      let total = 0;

      Object.values(this.usd).forEach((value) => {
        total += parseInt(value);
      });

      return total;
    },
  },
};
</script>
